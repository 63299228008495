import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RowCellViewModule} from '@aztrix/components/row-cell-view';
import {SearchQueryModule} from '@aztrix/components/search-query';
import {TranslatePipe} from '@aztrix/translate';
import {IconsModule} from '@aztrix/icons';

import {LanguageSelectorModalComponent} from './language-selector-modal.component';
import {HelperPipesModule} from '@aztrix/helpers';

@NgModule({
  declarations: [LanguageSelectorModalComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IconsModule,
    HelperPipesModule,
    TranslatePipe,
    RowCellViewModule,
    SearchQueryModule,
  ],
  exports: [LanguageSelectorModalComponent],
})
export class LanguageSelectorModalModule {}
