import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {OverlayService} from '@aztrix/components/overlay';
import {fuzzySort} from '@aztrix/helpers';
import {TranslateService} from '@aztrix/translate';
import {map, startWith} from 'rxjs/operators';

@Component({
  selector: 'ax-language-selector-modal',
  templateUrl: './language-selector-modal.component.html',
  styleUrls: ['./language-selector-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageSelectorModalComponent implements OnInit {
  @Input() languageCodes: string[];
  @Output() didSelectLanguage = new EventEmitter<string>();

  _query = new UntypedFormControl('');
  _languages: {languageCode: string; label: string}[];

  _searchResults$ = this._query.valueChanges.pipe(
    startWith(''),
    map((query) => {
      if (query === '') {
        return this._languages;
      }
      return fuzzySort(this._languages, query, ['label']);
    })
  );

  private _availableLanguages = this._translate.getLangs();

  constructor(
    public _translate: TranslateService,
    private _overlay: OverlayService
  ) {}

  ngOnInit() {
    this._languages = this.languageCodes.map((languageCode) => ({
      languageCode,
      label: this._translate.instant(`languageCode.${languageCode}.label`),
    }));
  }

  canSelectLanguage(lang: string) {
    return this._availableLanguages.includes(lang);
  }

  selectLanguage(languageCode: string): void {
    this._overlay.closeModal();
    this.didSelectLanguage.next(languageCode);
  }
}
