<div class="ax-modal-body-content">
  <ax-search-query
    class="query"
    [formControl]="_query"
    placeholder="search.placeholder.language"
  ></ax-search-query>

  <ng-container *ngFor="let language of _searchResults$ | async">
    <div
      class="c-settings__row is-hoverable cy-language"
      [ngClass]="{'is-disabled': !canSelectLanguage(language.languageCode)}"
      (click)="selectLanguage(language.languageCode)"
    >
      <ax-row-cell-view>
        <div class="row-cell-view-left">
          <ax-icon
            class="icon c-settings__country-flag"
            [name]="'flags:' + (language.languageCode | flagForLanguage)"
          ></ax-icon>
        </div>
        <div class="row-cell-view-middle">
          <span class="c-settings__country-label">{{ language.label }}</span>
        </div>
      </ax-row-cell-view>
    </div>
  </ng-container>
</div>
